/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type image_space_picture$ref: FragmentReference;
declare export opaque type image_space_picture$fragmentType: image_space_picture$ref;
export type image_space_picture = {|
  +nameId: ?string,
  +extension: ?string,
  +predictName: ?string,
  +$refType: image_space_picture$ref,
|};
export type image_space_picture$data = image_space_picture;
export type image_space_picture$key = {
  +$data?: image_space_picture$data,
  +$fragmentRefs: image_space_picture$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "image_space_picture",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extension",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "predictName",
      "storageKey": null
    }
  ],
  "type": "SpacePicture",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '5ce34e5d2ba72aad7c3aae231b8ba0b4';

module.exports = node;
