/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { useFragment, graphql } from 'react-relay';
import { isMobile } from 'react-device-detect';
import Icon from '@/core/icon.js';
import { ORImageSpace as Image } from '@/core/image';
import { useTranslation } from '@/helpers/translate';
// import { query } from './card-skin1';

const query = graphql`
  fragment cardSkinHome_item on Space
  @argumentDefinitions(lang: { type: "String!" }, category: { type: "String"} ) {
    title(lang: $lang)
    rate
    reviewCounter
    urlByLang(lang: $lang)
    pictures(count: 1) {
      ...image_space_picture
    }
    p: dataByCategory(category: $category) {
      categorySelected
      price {
        amount_excl_tax
      }
    }
  }
`;

function SkinHome({ item, options = {} }) {
  const { t } = useTranslation();
  const JSActive = typeof window !== 'undefined';

  const classes = {
    lvl0: 'carousel-cell-space-home md:cursor-pointer',
  };

  if (options.lvl0) {
    classes.lvl0 = options.lvl0;
  }

  function getUrlSSR() {
    return `${item?.urlByLang}`;
  }

  function getUrl() {
    return `${item?.urlByLang}?category=${item?.p?.categorySelected}`;
  }

  function getImageProps() {
    return options?.imageProps || {};
  }

  useEffect(() => {
    if (JSActive) {
      // Mettre à jour le lien lorsque le JavaScript est actif
      const link = document.getElementById('active-link');
      link.href = getUrl();
    }
  }, [JSActive]);

  if (!item) return null;

  return (
    <a
      id="active-link"
      href={getUrlSSR()}
      className={classes.lvl0}
      onClick={() => { window.open(getUrl(), '_self'); }}
    >
    {options.contentPrice || null}
      {!item?.pictures?.length || (
      <div className="relative block w-full h-full overflow-hidden rounded bg-white-dark2">
        <Image
          {...getImageProps()}
          key={item.pictures[0].__id}
          defaultAlt={item.title}
          cardsSpacesCities
          picture={item.pictures[0]}
        />
      </div>
      )}
      <div className="rounded-b-lg absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-black/[0] opacity-90" />
        <div className="absolute flex items-center bottom-4 right-4 left-4">
          <div className="pb-1 grow">
            <div className="text-balance overflow-hidden overflow-ellipsis text-white line-clamp-3 leading-5 text-[15px]">
              {item.title}
            </div>
            <div className="block mt-3 text-sm">
              <span className="bg-black bg-opacity-70 px-[10px] py-[6px] rounded text-white">
                <span className="font-medium">{item?.p?.price?.amount_excl_tax}€ </span>
                <span className="font-light">{t('core:pricebyday')}</span>
              </span>
            </div>
          </div>
          {!item.reviewCounter || (
          <div className="h-5 pl-2">
            <div className="flex flex-col items-center justify-center">
              <div className="flex">
                {!item.rate || (
                  <Icon name="star" className="w-3 fill-current text-blue-light2" />
                )}
                <span className="mx-1 text-sm text-blue-light2">{item.rate}</span>
              </div>
            </div>
          </div>
          )}
        </div>
    </a>
  );
}

export { SkinHome, query };

export default function RootComponent({ item: itemData, ...props }) {
  const item = useFragment(query, itemData);

  return <SkinHome {...{ item }} {...props} />;
}
