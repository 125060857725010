import React, { useEffect, useState, useMemo } from 'react';
import Image from 'next/image';
import { graphql, useFragment } from 'react-relay';
import { useTranslation } from '@/helpers/translate';
import { useZusHomeStore } from '@/helpers/context';
import { isClient } from '@/helpers/common.js';

const query = graphql`
  fragment image_picture on Picture {
    nameId
    extension
    predictName
  }
`;

const querySpacePublic = graphql`
  fragment image_space_picture on SpacePicture {
    nameId
    extension
    predictName
  }
`;

function ORImage({
  picture,
  extension,
  options,
  defaultAlt,
  noFlickity,
  noFlickityViewer,
  topPicturesGuides,
  topPicturesGuidesCards,
  topPicturesMain,
  topPicturesSmall,
  cardsPartners,
  cardsSpaces,
  cardsSpacesCities,
  priority,
  loading,
}) {
  const [cssOpa, setCssOpa] = useState(isClient() ? 'opacity-0' : 'opacity-100');
  const { extWebpJpg } = useZusHomeStore.getState();
  const newExtension = extension || extWebpJpg;
  const { t } = useTranslation();
  const hdurl = `https://i.officeriders.com/${picture.nameId}-500-*-${picture.extension}.${newExtension}`;
  const [imageLoaded, setImageLoaded] = useState(false);

  const imgprops = useMemo(() => ({
    sizes: '(max-width: 480px) 200px, (max-width: 1680px) 500px, (max-width: 3000px) 600px, (min-width: 3001px) 600px, 100vw',
    src: hdurl,
  }), [hdurl, imageLoaded]);

  const onLoad = () => {
    setImageLoaded(true);
    setCssOpa('opacity-100');
  };

  function getUpdatedImgProps() {
    const sizeConfigs = {
      default: '(max-width: 480px) 200px, (max-width: 1680px) 500px, (max-width: 3000px) 600px, (min-width: 3001px) 600px, 100vw',
      noFlickity: '(max-width: 480px) 200px, (max-width: 1440px) 400px, (max-width: 1680px) 500px, (min-width: 1680px) 700px, 100vw',
      noFlickityViewer: '(max-width: 480px) 400px, (max-width: 1680px) 800px, (min-width: 1680px) 800px, 100vw',
      topPicturesGuides: '(max-width: 480px) 200px, (max-width: 1680px) 400px, (min-width: 1680px) 400px, 100vw',
      topPicturesMain: '(max-width: 480px) 200px, (max-width: 1680px) 500px, (max-width: 3000px) 600px, (min-width: 3001px) 600px, 100vw',
      topPicturesSmall: '(max-width: 480px) 100px, (max-width: 1680px) 300px, (max-width: 3000px) 300px, (min-width: 3001px) 400px, 100vw',
      cards: '(max-width: 480px) 200px, (max-width: 1680px) 500px, (max-width: 3000px) 500px, (min-width: 3001px) 600px, 100vw',
    };

    // Choose the appropriate sizes based on props
    let selectedSizes = sizeConfigs.default;

    if (noFlickity) {
      selectedSizes = sizeConfigs.noFlickity;
    }
    if (noFlickityViewer) {
      selectedSizes = sizeConfigs.noFlickityViewer;
    }
    if (topPicturesGuides || topPicturesGuidesCards) {
      selectedSizes = sizeConfigs.topPicturesGuides;
    }
    if (topPicturesMain) {
      selectedSizes = sizeConfigs.topPicturesMain;
    }
    if (topPicturesSmall) {
      selectedSizes = sizeConfigs.topPicturesSmall;
    }
    if (cardsPartners || cardsSpaces || cardsSpacesCities) {
      selectedSizes = sizeConfigs.cards;
    }
    const Hdurl = `https://i.officeriders.com/${picture.nameId}-800-*-${picture.extension}.${newExtension}`;

    const updatedImgProps = {
      ...imgprops,
      sizes: selectedSizes,
      src: Hdurl,
    };
    if (priority) {
      updatedImgProps.priority = priority;
    }
    if (loading) {
      updatedImgProps.loading = 'eager';
    }
    if (options?.currentIndex === 1) {
      updatedImgProps.loading = 'eager';
    }
    return updatedImgProps;
  }
  const updatedImgProps = getUpdatedImgProps();
  const imgAlt = options && options.alt && picture?.predictName
  ? `${t(`space:pictures_predict.${picture?.predictName}`)} ${t('core:in')} ${options.alt} - ${options.index}`
  : defaultAlt;

  return (
    <Image
      {...updatedImgProps}
      fill
      className={`transition object-cover w-full h-full ${imageLoaded ? 'opacity-100' : cssOpa}`}
      alt={imgAlt}
      onLoad={onLoad}
    />
  );
}
function ORImageSpace({ picture: _dataPicture, ...props }) {
  const picture = useFragment(querySpacePublic, _dataPicture || null);

  return <ORImage {...{ picture }} {...props} />;
}

export {
  query,
  querySpacePublic,
  ORImage,
  ORImageSpace,
};

export default function RootComponent({ picture: _dataPicture, ...props }) {
  const picture = useFragment(query, _dataPicture || null);
  return <ORImage {...{ picture }} {...props} />;
}
