/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { image_space_picture$ref } from "./image_space_picture.graphql";
import type { FragmentReference } from "relay-runtime";
declare export opaque type cardSkinHome_item$ref: FragmentReference;
declare export opaque type cardSkinHome_item$fragmentType: cardSkinHome_item$ref;
export type cardSkinHome_item = {|
  +title: ?string,
  +rate: ?number,
  +reviewCounter: ?number,
  +urlByLang: ?string,
  +pictures: ?$ReadOnlyArray<?{|
    +$fragmentRefs: image_space_picture$ref
  |}>,
  +p: ?{|
    +categorySelected: ?string,
    +price: ?{|
      +amount_excl_tax: ?number
    |},
  |},
  +$refType: cardSkinHome_item$ref,
|};
export type cardSkinHome_item$data = cardSkinHome_item;
export type cardSkinHome_item$key = {
  +$data?: cardSkinHome_item$data,
  +$fragmentRefs: cardSkinHome_item$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "category"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "cardSkinHome_item",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewCounter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "urlByLang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "count",
          "value": 1
        }
      ],
      "concreteType": "SpacePicture",
      "kind": "LinkedField",
      "name": "pictures",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "image_space_picture"
        }
      ],
      "storageKey": "pictures(count:1)"
    },
    {
      "alias": "p",
      "args": [
        {
          "kind": "Variable",
          "name": "category",
          "variableName": "category"
        }
      ],
      "concreteType": "DataByCategory",
      "kind": "LinkedField",
      "name": "dataByCategory",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categorySelected",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Price",
          "kind": "LinkedField",
          "name": "price",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount_excl_tax",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Space",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '715d222409c04e900a3ef596eccf952e';

module.exports = node;
